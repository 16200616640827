<div class="container-fluid">
  <mat-toolbar class="fixed-navbar shadow">
    <div class="row align-items-center">
      <div class="col-auto">
        <img src="../../../assets/logo-sara-piccolo2.png">
      </div>
      <div class="col">
        <div class="row justify-content-center">
          <h2>Dashboard Soilgrids</h2>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>
