import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as L from "leaflet";
import * as L1 from "leaflet.markercluster";
import {control} from "leaflet";

@Component({
  selector: 'app-mapChart',
  templateUrl: './mapChart.component.html',
  styleUrls: ['./mapChart.component.css']
})
export class MapChartComponent implements OnChanges, OnInit {

  @Input() markers: any[] = [];
  @Input() selectedLatitude: number;
  @Input() selectedLongitude: number;
  @Input() source: string;
  @Output() markerClicked = new EventEmitter<{ lat: number, lng: number }>();


  private markerClusterGroup = L.markerClusterGroup();

  private marker: L.Marker;
  public map: L.Map;

  public isSatelliteView: boolean = false;


  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['markers'].firstChange) {
      this.setMarker();
    }

  }

  ngOnInit() {
    this.drawMap();
  }


  public drawMap(): void {
    this.map = L.map('map', {
      scrollWheelZoom: true,
      zoomControl: false
    }).setView([this.selectedLatitude, this.selectedLongitude], 11);
    L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {}).addTo(this.map);


  };

  public changeView(): void {
    if (this.isSatelliteView) {
      // Imposta la vista satellitare
      L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      }).addTo(this.map);
    } else {
      // Imposta la mappa di base
      L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {}).addTo(this.map);
    }

  };


  public setMarker(): void {
    const myIcon = L.icon({
      iconUrl: 'assets/images/marker.png',
      iconSize: [17, 28],
      popupAnchor: [0, -10]
    });

    // Clear the previous markers from the layer
    if (this.markerClusterGroup) {
      this.markerClusterGroup.clearLayers()
    }

    let markerGroup = L.markerClusterGroup(); // creates a new LayerGroup

    for (let i = 0; i < this.markers.length; i++) {
      const marker = L.marker([this.markers[i]['latitudine'], this.markers[i]['longitudine']], {icon: myIcon});
      marker.on('click', () => {
        const lat = this.markers[i]['latitudine'];
        const lng = this.markers[i]['longitudine'];
        this.markerClicked.emit({lat, lng})
      });
      const pointDescription = `Latitudine: ${this.markers[i]['latitudine']} - Longitudine: ${this.markers[i]['longitudine']}`;
      marker.bindPopup(pointDescription);
      marker.on('mouseover', () => {
        marker.openPopup();
      });
      marker.on('mouseout', () => {
        marker.closePopup();
      });
      markerGroup.addLayer(marker); // adds the marker to the MarkerClusterGroup
    }
    this.markerClusterGroup = markerGroup;
    this.map.addLayer(markerGroup);
    let markerBounds = markerGroup.getBounds();
    this.map.fitBounds(markerBounds);
  };
}


