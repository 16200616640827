import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {DataService} from "../../../service/data.service";
import {NgxSpinnerService} from "ngx-spinner";
import {FormControl} from "@angular/forms";


@Component({
  selector: 'app-climateChart',
  templateUrl: './climateChart.component.html',
  styleUrls: ['./climateChart.component.css']
})

export class ClimateChartComponent {

  @Input() climateData: any[] = [];

  @Input() source: string;

  @Input() selectedClimateParameter: FormControl;

  @Input() mergedOption: any = {};



  public option: any = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: []
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {

      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: []
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: '',
        type: '',
        stack: '',
        data: []
      },

      {
        name: '',
        type: '',
        stack: '',
        data: []
      }
    ]
  };





  constructor() {
  }




}

