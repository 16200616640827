<mat-card>
  <div class="container-fluid">
    <mat-form-field appearance="outline" style="width: 38%; padding-top: 5px">
      <mat-label>Profondità di rilevazione</mat-label>
      <mat-select (selectionChange)="changingDepth($event.value)" value = "0-5cm">
        <mat-option value="0-5cm">0-5cm</mat-option>
        <mat-option value="0-30cm">0-30cm</mat-option>
        <mat-option value="5-15cm">5-15cm</mat-option>
        <mat-option value="15-30cm">15-30cm</mat-option>
        <mat-option value="30-60cm">30-60cm</mat-option>
        <mat-option value="60-100cm">60-100cm</mat-option>
        <mat-option value="100-200cm">100-200cm</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="mat-elevation-z8" >
      <table mat-table [dataSource]="dataTable" >

        <!-- Name Column -->
        <ng-container matColumnDef="parametro">
          <th mat-header-cell *matHeaderCellDef> Parametro (unità di misura) </th>
          <td mat-cell *matCellDef="let element"> {{
              element.parametro === 'bdod' ? 'Densità apparente del suolo (bdod) (cg/cm³)'
            : element.parametro === 'cec' ? 'Capacità di scambio cationico (cec) (mmol(c)/kg)'
            : element.parametro === 'cfvo' ? 'Frazione volumetrica di frammenti grossolani (cfvo) (cm³/dm³)'
            : element.parametro === 'clay' ? 'Argilla (g/kg)'
            : element.parametro === 'nitrogen' ? 'Azoto (cg/kg)'
            : element.parametro === 'ocd' ? 'Densità del carbonio organico (ocd) (g/dm³)'
            : element.parametro === 'phh2o' ? 'pH del suolo (pH*10)'
            : element.parametro === 'sand' ? 'Sabbia (g/kg)'
            : element.parametro === 'silt' ? 'Limo (g/kg)'
            : element.parametro === 'soc' ? 'Contenuto di carbonio organico del suolo nella frazione di terra fine (soc) (dg/kg)'
            : element.parametro === 'ocs' ? 'Stock di carbonio organico (ocs) (t/ha)'
            :element.parametro
            }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="media">
          <th mat-header-cell *matHeaderCellDef> Media </th>
          <td mat-cell *matCellDef="let element"> {{element.media}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="mediana">
          <th mat-header-cell *matHeaderCellDef> Mediana </th>
          <td mat-cell *matCellDef="let element"> {{element.mediana}} </td>
        </ng-container>

        <ng-container matColumnDef="incertezza">
          <th mat-header-cell *matHeaderCellDef> Incertezza </th>
          <td mat-cell *matCellDef="let element"> {{element.incertezza}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5]"
                     showFirstLastButtons
                     aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
    <div class="row mx-auto">
      <p>{{source}}</p>
    </div>
  </div>
<br>
</mat-card>
<br>

