import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import {MatCardModule} from "@angular/material/card";
import { DashboardPageComponent } from './pages/dashboardPage/dashboardPage.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import { ToolbarComponent } from './pages/toolbar/toolbar.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MatNativeDateModule } from '@angular/material/core';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import { LeafletModule} from "@asymmetrik/ngx-leaflet";
import {
  SoilgridsTableComponent,
} from './pages/dashboardPage/soilgridsTable/soilgridsTable.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import { ChipsComponent } from './pages/dashboardPage/chips/chips.component';
import {MatLegacyChipsModule} from "@angular/material/legacy-chips";
import {NgxEchartsModule} from "ngx-echarts";
import * as echarts from 'echarts';
import {
  AtmosphereChartComponent,
} from './pages/dashboardPage/atmosphereChart/atmosphereChart.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDialogModule} from '@angular/material/dialog';
import {HttpClientModule} from "@angular/common/http";
import {LeafletMarkerClusterModule} from "@asymmetrik/ngx-leaflet-markercluster";
import { MapChartComponent } from './pages/dashboardPage/mapChart/mapChart.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSelectModule} from "@angular/material/select";
import {AngularStickyThingsModule} from "@w11k/angular-sticky-things";
import {MatSnackBar, MatSnackBarModule} from "@angular/material/snack-bar";
import {NgxSpinnerModule} from "ngx-spinner";
import {ClimateChartComponent} from "./pages/dashboardPage/climateChart/climateChart.component";


@NgModule({
  declarations: [
    AppComponent,
    DashboardPageComponent,
    ToolbarComponent,
    SoilgridsTableComponent,
    ChipsComponent,
    ClimateChartComponent,
    AtmosphereChartComponent,
    MapChartComponent

  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatCardModule,
        MatExpansionModule,
        MatInputModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        LeafletModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatLegacyChipsModule,
        NgxEchartsModule.forRoot({
            echarts,
        }),
        MatTooltipModule,
        MatDialogModule,
        FormsModule,
        HttpClientModule,
        LeafletMarkerClusterModule,
        MatSlideToggleModule,
        MatSelectModule,
        AngularStickyThingsModule,
        MatSnackBarModule,
        NgxSpinnerModule,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
