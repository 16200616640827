import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-soilgridsTable',
  templateUrl: './soilgridsTable.component.html',
  styleUrls: ['./soilgridsTable.component.css']
})
export class SoilgridsTableComponent implements OnChanges{
  public displayedColumns: string[] = ['parametro', 'media', 'mediana', 'incertezza'];

  @Input() filteredSoilgridsData: SoilgridsData[] = [];

  @Input() source: string;

  @Output() depthChanged = new EventEmitter<string>();




  public dataTable: MatTableDataSource<SoilgridsData>;


  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.updateTableContent();
    this.dataTable.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateTableContent();
  }

  public updateTableContent(): void {
    this.dataTable = new MatTableDataSource<SoilgridsData>(this.filteredSoilgridsData);
    this.dataTable.paginator = this.paginator;
  }

  public changingDepth(depth: string) {
    this.depthChanged.emit(depth)
  };


}
export interface SoilgridsData {
  parametro: string;
  media: number;
  mediana: number;
  incertezza: number
}




