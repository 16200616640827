<app-toolbar></app-toolbar>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  [fullScreen]="true"
><p style="color: white">Caricamento...</p></ngx-spinner>
<div class="container-fluid">
  <ng-container>
    <div class="col-12 col-sm-12">
      <br>
      <div #spacer></div>
      <mat-expansion-panel id="myControl" stickyThing
                           (stickyStatus)="panelOpenState = !$event.isSticky"
                           [spacer]="spacer"
                           (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false"
                           [(expanded)] = panelOpenState
                           [style]="{'min-height': panelOpenState ? '250px' : '62px'}">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon id="filterIcon">filter_alt</mat-icon>&nbsp;&nbsp;<h5 style="margin-top: 10px">Filtri</h5>
            <app-chips *ngIf="!panelOpenState"
                       [chips]="[selectedCity.value, selectedStartDate.value.format('DD/MM/YYYY'), selectedEndDate.value.format('DD/MM/YYYY')]"></app-chips>

          </mat-panel-title>

        </mat-expansion-panel-header>
        &nbsp;
        <div class="row mx-auto">
          <div class="col-12 col-sm-12 col-lg-3 col-xl-2">
            <p class="title">Città</p>
            <form class="example-form">
              <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
                <mat-label>Città</mat-label>
                <input type="text"
                       placeholder="Digita qui"
                       aria-label="Cities"
                       matInput
                       [formControl]="selectedCity"
                       [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let city of filteredCities | async" [value]="city">
                    {{city}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </div>
          <div class="col-12 col-sm-12 col-lg-3 col-xl-3">
            <p class="title">Periodo</p>
            <mat-form-field appearance="outline" style="width: 47%">
              <mat-label id="label1">Data iniziale</mat-label>
              <input matInput [matDatepicker]="dp1"
                     [formControl]="selectedStartDate"
                     (keydown)="$event.preventDefault();"
                     [readonly]="true"
                     [min]="'1960-01-01'"
                     [max]="'2023-06-30'">
              <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
              <mat-datepicker #dp1 ></mat-datepicker>
            </mat-form-field>
            &nbsp;
            <mat-form-field appearance="outline" style="width: 47%">
              <mat-label id="label2">Data finale</mat-label>
              <input matInput  [matDatepicker]="dp2"
                     [formControl]="selectedEndDate"
                     (keydown)="$event.preventDefault();"
                     [readonly]="true"
                     [min]="selectedStartDate.value"
                     [max]="'2023-06-30'">
              <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2>
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-12 col-lg-4 col-xl-2">
            <p class="title">Parametro climatico</p>
            <mat-form-field appearance="outline">
              <mat-label>Parametro climatico</mat-label>
              <mat-select [formControl]="selectedClimateParameter">
                <mat-option value="Solar_Radiation">Radiazione solare</mat-option>
                <mat-option value="Relative_Humidity">Umidità relativa</mat-option>
                <mat-option value="Precipitation">Precipitazioni</mat-option>
                <mat-option value="Temperature_Air">Temperatura dell'aria</mat-option>
                <mat-option value="Wind_Speed">Velocità del vento</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-12 col-lg-4 col-xl-2">
            <p class="title">Parametro atmosferico</p>
            <mat-form-field appearance="outline">
              <mat-label>Parametro atmosferico</mat-label>
              <mat-select [formControl]="selectedAtmosphereParameter">
                <mat-option value="Dust">Polveri</mat-option>
                <mat-option value="Nitrogen Dioxide">Monossido di azoto</mat-option>
                <mat-option value="Ozone">Ozono</mat-option>
                <mat-option value="PM2.5 Aerosol">PM2.5</mat-option>
                <mat-option value="PM10 Aerosol">PM10</mat-option>
                <mat-option value="Sulphur Dioxide">Diossido di zolfo</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-sm-12 col-lg-4 col-xl-3">
            <p class="title">Tipologia di statistica</p>
            <mat-form-field appearance="outline">
              <mat-label>Statistica</mat-label>
              <mat-select [formControl]="selectedTimeStatistic">
                <mat-option value="mensile">Statistica mensile</mat-option>
                <mat-option value="giornaliera">Statistica giornaliera</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-auto justify-content-end">
          <div class="col-12 col-sm-12 col-lg-6 col-xl-2">
            <button mat-raised-button id="confirm" (click)="cityChanged ? getMarkersPoints() : getDataClimate()">
              <mat-icon>check</mat-icon>&nbsp;&nbsp;Conferma
            </button> </div>
          <div class="col-12 col-sm-12 col-lg-6 col-xl-2">
            <button mat-raised-button id="filterDeleter" (click)="clearFilters()"><mat-icon>delete</mat-icon>&nbsp;&nbsp;Reimposta filtri</button>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <br>
  </ng-container>
  <ng-container>
    <div class="row mx-auto" [style]="{'position': panelOpenState ? '' : 'relative', 'top': panelOpenState ? '' : '75px'}">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <app-mapChart
          [selectedLongitude]="selectedLongitude"
          [selectedLatitude]="selectedLatitude"
          [markers]="markers"
          [source]="'Fonte: SoilGrids'"
          (markerClicked)="onMarkerClicked($event)"
        ></app-mapChart>
        <br>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <app-soilgridsTable
          [filteredSoilgridsData]="parametersData"
          [source]="'Fonte: SoilGrids'"
          (depthChanged)="onChangingDepth($event)"></app-soilgridsTable>
      </div>
    </div>
    <div class="row mx-auto" [style]="{'position': panelOpenState ? '' : 'relative', 'top': panelOpenState ? '' : '75px'}">
      <div class="col-12 col-sm-12">
        <app-climateChart
          [source]="'Fonte: Copernicus'"
          [selectedClimateParameter]="selectedClimateParameter"
          [mergedOption]="climateMergedOption"
        ></app-climateChart>
        <br>
      </div>
    </div>
    <div class="row mx-auto" [style]="{'position': panelOpenState ? '' : 'relative', 'top': panelOpenState ? '' : '75px'}">
      <div class="col-12 col-sm-12">
        <app-atmosphereChart
          [source]="'Fonte: Copernicus'"
          [selectedAtmosphereParameter]="selectedAtmosphereParameter"
          [mergedOption]="atmosphereMergedOption"
          ></app-atmosphereChart>
      </div>
    </div>
    <div class="row mx-auto" [style]="{'position': panelOpenState ? '' : 'relative', 'top': panelOpenState ? '' : '75px'}">
    </div>
  </ng-container>


  <br><br><br><br>
</div>



