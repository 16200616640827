<mat-chip-list [selectable]="false"
  cdkDropList
  cdkDropListOrientation="horizontal">
  <div id="filters" class="row mx-auto">
    <ng-container *ngFor="let element of finalChips">
      <div class="col-4"><mat-chip selected >{{element}}</mat-chip></div>
    </ng-container>
  </div>
</mat-chip-list>




