import {Component, Inject, Input} from '@angular/core';
import {FormControl} from "@angular/forms";


@Component({
  selector: 'app-atmosphereChart',
  templateUrl: './atmosphereChart.component.html',
  styleUrls: ['./atmosphereChart.component.css']
})
export class AtmosphereChartComponent {

  @Input() atmosphereData: any[] = [];

  @Input() source: string;

  @Input() selectedAtmosphereParameter: FormControl;

  @Input() mergedOption: any = {};

  public option: any= {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: []
    },

    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: []
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: '',
        type: 'bar',
        label: '',


        data: []
      }
    ]
  };

  constructor() {
  }

}

