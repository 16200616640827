<mat-card>
  <div class="container-fluid">
    <div class="row mx-auto" style="padding-top: 10px">
      <p *ngIf="selectedAtmosphereParameter.value == 'Dust'">Parametro atmosferico scelto: <strong>Polveri</strong></p>
      <p *ngIf="selectedAtmosphereParameter.value == 'Nitrogen Dioxide'">Parametro atmosferico scelto: <strong>Monossido di azoto</strong></p>
      <p *ngIf="selectedAtmosphereParameter.value == 'Ozone'">Parametro atmosferico scelto: <strong>Ozono</strong></p>
      <p *ngIf="selectedAtmosphereParameter.value == 'PM2.5 Aerosol'">Parametro atmosferico scelto: <strong>PM2.5</strong></p>
      <p *ngIf="selectedAtmosphereParameter.value == 'PM10 Aerosol'">Parametro atmosferico scelto: <strong>PM10</strong></p>
      <p *ngIf="selectedAtmosphereParameter.value == 'Sulphur Dioxide'">Parametro atmosferico scelto: <strong>Diossido di zolfo</strong></p>
    </div>
    <div class="row mx-auto">
      <div echarts [options]="option" [merge]="mergedOption" class="demo-chart"></div>
    </div>
    <div class="row mx-auto">
      <p><strong>{{source}}</strong></p>
    </div>
  </div>

</mat-card>
