<mat-card>
  <div class="container-fluid">
    <div class="row mx-auto" style="padding-top: 10px">
      <p *ngIf="selectedClimateParameter.value == 'Solar_Radiation'">Parametro climatico scelto: <strong>Radiazione solare</strong></p>
      <p *ngIf="selectedClimateParameter.value == 'Relative_Humidity'">Parametro climatico scelto: <strong>Umidità relativa</strong></p>
      <p *ngIf="selectedClimateParameter.value == 'Precipitation'">Parametro climatico scelto: <strong>Precipitazioni</strong></p>
      <p *ngIf="selectedClimateParameter.value == 'Temperature_Air'">Parametro climatico scelto: <strong>Temperatura dell'aria</strong></p>
      <p *ngIf="selectedClimateParameter.value == 'Wind_Speed'">Parametro climatico scelto: <strong>Velocità del vento</strong></p>
    </div>
    <div class="row mx-auto">
      <div echarts [options]="option" [merge]="mergedOption" class="demo-chart"></div>
    </div>
    <div class="row mx-auto">
      <p><strong>{{source}}</strong></p>
    </div>
  </div>

</mat-card>
