import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {filter, Observable} from "rxjs";
import {map, startWith} from 'rxjs/operators';
import {DataService} from "../../service/data.service";
import {mark} from "@angular/compiler-cli/src/ngtsc/perf/src/clock";
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-dashboardPage',
  templateUrl: './dashboardPage.component.html',
  styleUrls: ['./dashboardPage.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
  ],
})
export class DashboardPageComponent implements OnInit{


  public data: any;

  private DEFAULT_START_DATE: string = "2020-05-01";

  private DEFAULT_END_DATE: string = "2020-06-30";

  private DEFAULT_CITY : string = "Lecce";

  private DEFAULT_LATITUDE: number = 40.351515;

  private DEFAULT_LONGITUDE: number = 18.175016;

  private DEFAULT_CLIMATE_PARAMETER: string = 'Solar_Radiation';

  private DEFAULT_ATMOSPHERE_PARAMETER: string = 'Dust';

  private DEFAULT_STATISTIC: string = 'mensile'

  public markers: any [] = [];

  public parametersData: any [] = [];

  public panelOpenState: boolean = true;

  public climateStateControl: boolean = false;

  public atmosphereStateControl: boolean = false;

  public selectedStartDate: FormControl = new FormControl(_moment(this.DEFAULT_START_DATE, "YYYY-MM-DD"));

  public selectedEndDate: FormControl = new FormControl(_moment(this.DEFAULT_END_DATE, "YYYY-MM-DD"));

  public selectedClimateParameter: FormControl = new FormControl({value: this.DEFAULT_CLIMATE_PARAMETER, disabled: this.climateStateControl});

  public selectedAtmosphereParameter: FormControl = new FormControl({value: this.DEFAULT_ATMOSPHERE_PARAMETER, disabled: this.atmosphereStateControl});

  public selectedTimeStatistic: FormControl = new FormControl(this.DEFAULT_STATISTIC);

  public selectedCity: FormControl = new FormControl(this.DEFAULT_CITY);

  public selectedLatitude: number = this.DEFAULT_LATITUDE;

  public selectedLongitude: number = this.DEFAULT_LONGITUDE;

  public selectedMakerLatitude: number | null = null;

  public selectedMarkerLongitude: number | null = null;

  public selectedDepth: string = "0-5cm"

  public citiesList: string[] = [];

  public climateTimeList: string [] = [];

  public climateMaxValue: number [] = [];

  public climateMinValue: number [] = [];

  public climateAvgValue: number [] = [];

  public atmosphereTimeList: string [] = [];

  public atmosphereMaxValue: number [] = [];

  public atmosphereMinValue: number [] = [];

  public atmosphereAvgValue: number [] = [];

  public precipitationValue: number [] = [];

  public cityChanged: boolean = true;

  public climateMergedOption: any = {};

  public atmosphereMergedOption: any = {};

  public filteredCities: Observable<string[]> | undefined;

  public selectedOption: string = ''; // Inizializza con un valore predefinito
  public inputValue: string = ''; // Inizializza con un valore predefinito

  constructor(private dataService: DataService, private spinner: NgxSpinnerService) {
    this.selectedCity.valueChanges.subscribe( () => {
      this.cityChanged = true;
    });

  }

  ngOnInit(): void {
    this.filteredCities = this.selectedCity.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.getCitiesList();

  };


  public getMarkersPoints() {
    this.spinner.show();

    this.dataService.getSoilgridsSinglePointsData(this.selectedCity.value).subscribe(
      (data) => {
        this.data = data;
        this.selectedLatitude = this.data[0].latitudine;
        this.selectedLongitude = this.data[0].longitudine;
        const latLongs: any = {};
        this.markers = [];

        for (let i = 0; i < this.data.length; i++) {
          let lat = this.data[i]['latitudine'];
          let long = this.data[i]['longitudine'];

          // verifica se l'oggetto è già stato creato e, se non è stato creato, lo aggiunge all'array `this.markers`
          if (!latLongs[`${lat}-${long}`]) {
            latLongs[`${lat}-${long}`] = true; // segna latitudine e longitudine come utilizzati
            let marker = { latitudine: lat, longitudine: long };

            this.markers.push(marker);

          }
        }
        this.cityChanged = false;

      },
      () => {
        this.spinner.hide();
        this.dataService.openSnackBar('Nessun dato trovato', 'OK', 4000)

      },
      () => {
        this.spinner.hide();

      }
    );

  };

  public getDataTable(): void {
    let filteredData = this.data.filter((obj: { latitudine: number | null; longitudine: number | null; profondita: string | "" }) =>
      obj.latitudine === this.selectedMakerLatitude && obj.longitudine === this.selectedMarkerLongitude && obj.profondita === this.selectedDepth);

    this.parametersData = filteredData.map((obj: { parametro: any; media: any; mediana: any; incertezza: any; }) =>
      ({parametro: obj.parametro, media: obj.media, mediana: obj.mediana, incertezza: obj.incertezza}));
  };


  public getDataClimate(): void {
    const minStartDate = new Date ("1978-12-31")
    if (this.selectedStartDate.value < minStartDate) {
      this.dataService.openSnackBar('I dati climatici sono disponibili dal 01-01-1979. I dati sono atmosferici disponibili dal 01-05-2020', 'OK', 10000);
    } else {
      this.spinner.show();
      if (this.selectedTimeStatistic.value == 'mensile') {
        this.dataService.getMonthlyClimateData(this.selectedMakerLatitude, this.selectedMarkerLongitude, this.selectedStartDate.value.format('YYYY-MM-DD'), this.selectedEndDate.value.format('YYYY-MM-DD'), this.selectedClimateParameter.value).subscribe(
          (data) => {
            this.getDataAtmosphere();
            this.climateTimeList = [];
            this.climateMinValue = [];
            this.climateMaxValue = [];
            this.climateAvgValue = [];
            this.precipitationValue = [];

            data.forEach((item: any) => {
              const dateObject = new Date(item.data);
              const day = dateObject.getDate();
              const month = dateObject.getMonth() + 1;
              const year = dateObject.getFullYear();
              const formattedDate = `${day}-${month}-${year}`;

              this.climateTimeList.push(formattedDate);
              this.climateMinValue.push(item.minMensile);
              this.climateMaxValue.push(item.maxMensile);
              this.climateAvgValue.push(item.mediaMensile);
              this.precipitationValue.push(item.giorniPioggia);
            });

          },
          () => {
            this.spinner.hide();
          },
          () => {
            this.climateMergedOption = {
              tooltip: {
                trigger: 'axis',
                formatter: (params: any) => {
                  const dataIndex = params[0]?.dataIndex ?? 0;
                  const time = this.climateTimeList[dataIndex];
                  let tooltipContent = `Data: ${time}<br>`;

                  params.forEach((param: any) => {
                    const seriesName = param.seriesName;
                    const value = param.value;

                    if (seriesName === 'Valore minimo') {
                      tooltipContent += `Valore minimo: ${this.formatTooltipContent(value)}<br>`;
                    } else if (seriesName === 'Valore massimo') {
                      tooltipContent += `Valore massimo: ${this.formatTooltipContent(value)}<br>`;
                    } else if (seriesName === 'Valore medio') {
                      tooltipContent += `Valore medio: ${this.formatTooltipContent(value)}<br>`;
                    } else if (seriesName === 'Giorni di pioggia' && this.selectedClimateParameter.value === 'Precipitation') {
                      tooltipContent += `Giorni di pioggia: ${value}`;
                    }
                  });

                  return tooltipContent;
                }
              },
              legend: {
                data: ['Valore minimo', 'Valore medio', 'Valore massimo', this.selectedClimateParameter.value == 'Precipitation' ? 'Giorni di pioggia' : '']
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: this.climateTimeList
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter: this.climateAxisLabelFormatter.bind(this)
                }
              },

              series: [
                {
                  name: 'Valore minimo',
                  type: 'line',
                  stack: '',
                  data: this.climateMinValue
                },
                {
                  name: 'Valore medio',
                  type: 'line',
                  stack: '',
                  data: this.climateAvgValue
                },
                {
                  name: 'Valore massimo',
                  type: 'line',
                  stack: '',
                  data: this.climateMaxValue
                },
                {
                  name: 'Giorni di pioggia',
                  type: 'line',
                  stack: '',
                  data: this.selectedClimateParameter.value == 'Precipitation' ? this.precipitationValue : []
                }

              ]

            };
          }
        )
      } else {
        this.dataService.getDailyClimateData(this.selectedMakerLatitude, this.selectedMarkerLongitude, this.selectedStartDate.value.format('YYYY-MM-DD'), this.selectedEndDate.value.format('YYYY-MM-DD'), this.selectedClimateParameter.value).subscribe(
          (data) => {
            this.getDataAtmosphere();
            this.climateTimeList = [];
            this.climateMinValue = [];
            this.climateMaxValue = [];
            this.climateAvgValue = [];
            this.precipitationValue = [];

            data.forEach((item: any) => {
              const dateObject = new Date(item.data);

              // Estrai il giorno, mese e anno dalla data
              const day = dateObject.getDate();
              const month = dateObject.getMonth() + 1; // I mesi in JavaScript vanno da 0 a 11, quindi aggiungi 1
              const year = dateObject.getFullYear();

              // Costruisci la data nel formato "giorno-mese-anno"
              const formattedDate = `${day}-${month}-${year}`;

              // Continua con la tua logica per l'aggiunta dei valori alle serie
              this.climateTimeList.push(formattedDate);
              this.climateMinValue.push(item.minGiornaliero);
              this.climateMaxValue.push(item.maxGiornaliero);
              this.climateAvgValue.push(item.mediaGiornaliera);
            });

          },
          () => {
            this.spinner.hide()
          },
          () => {
            this.climateMergedOption = {
              tooltip: {
                trigger: 'axis',
                formatter: (params: any) => {
                  const dataIndex = params[0]?.dataIndex ?? 0;
                  const time = this.climateTimeList[dataIndex];
                  let tooltipContent = `Data: ${time}<br>`;

                  params.forEach((param: any) => {
                    const seriesName = param.seriesName;
                    const value = param.value;

                    if (seriesName === 'Valore minimo') {
                      tooltipContent += `Valore minimo: ${this.formatTooltipContent(value)}<br>`;
                    } else if (seriesName === 'Valore massimo') {
                      tooltipContent += `Valore massimo: ${this.formatTooltipContent(value)}<br>`;
                    } else if (seriesName === 'Valore medio') {
                      tooltipContent += `Valore medio: ${this.formatTooltipContent(value)}<br>`;
                    } else if (seriesName === 'Giorni di pioggia' && this.selectedClimateParameter.value === 'Precipitation') {
                      tooltipContent += `Giorni di pioggia: ${value}`;
                    }
                  });

                  return tooltipContent;
                }
              },
              legend: {
                data: ['Valore minimo', 'Valore medio', 'Valore massimo', this.selectedClimateParameter.value == 'Precipitation' ? 'Giorni di pioggia' : '']
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: this.climateTimeList
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter: this.climateAxisLabelFormatter.bind(this)
                }
              },
              series: [
                {
                  name: 'Valore minimo',
                  type: 'line',
                  stack: '',
                  data: this.climateMinValue
                },
                {
                  name: 'Valore medio',
                  type: 'line',
                  stack: '',
                  data: this.climateAvgValue
                },
                {
                  name: 'Valore massimo',
                  type: 'line',
                  stack: '',
                  data: this.climateMaxValue
                },
                {
                  name: 'Giorni di pioggia',
                  type: 'line',
                  stack: '',
                  data: this.selectedClimateParameter.value == 'Precipitation' ? this.precipitationValue : []
                }

              ]
            };
          }
        )
      }
    }

  }

  public getDataAtmosphere(): void {
    const minStartDate = new Date ("2020-04-30")
    if (this.selectedStartDate.value < minStartDate) {
      this.dataService.openSnackBar('I dati climatici sono disponibili dal 01-01-1979. I dati sono atmosferici disponibili dal 01-05-2020', 'OK', 10000);
    } else {
      if (this.selectedTimeStatistic.value == 'mensile') {
        this.dataService.getMonthlyAtmosphereData(this.selectedMakerLatitude, this.selectedMarkerLongitude, this.selectedStartDate.value.format('YYYY-MM-DD'), this.selectedEndDate.value.format('YYYY-MM-DD'), this.selectedAtmosphereParameter.value).subscribe(
          (data) => {

            this.atmosphereTimeList = [];
            this.atmosphereMinValue = [];
            this.atmosphereMaxValue = [];
            this.atmosphereAvgValue = [];

            data.forEach((item: any) => {
              const dateObject = new Date(item.data);
              const day = dateObject.getDate();
              const month = dateObject.getMonth() + 1;
              const year = dateObject.getFullYear();
              const formattedDate = `${day}-${month}-${year}`;

              this.atmosphereTimeList.push(formattedDate);
              this.atmosphereMinValue.push(item.min);
              this.atmosphereMaxValue.push(item.max);
              this.atmosphereAvgValue.push(item.avg);
            });

          },
          () => {
            this.spinner.hide();
          },
          () => {
            this.atmosphereMergedOption = {
              tooltip: {
                trigger: 'axis',
                formatter: (params: any) => {
                  const dataIndex = params[0]?.dataIndex ?? 0;
                  const time = this.atmosphereTimeList[dataIndex];
                  let tooltipContent = `Data: ${time}<br>`;

                  params.forEach((param: any) => {
                    const seriesName = param.seriesName;
                    const value = param.value;

                    if (seriesName === 'Valore minimo') {
                      tooltipContent += `Valore minimo: ${value.toLocaleString('it-IT', {maximumFractionDigits: 2}) + ' µg m^-3'}<br>`;
                    } else if (seriesName === 'Valore massimo') {
                      tooltipContent += `Valore massimo: ${value.toLocaleString('it-IT', {maximumFractionDigits: 2}) + ' µg m^-3'}<br>`;
                    } else if (seriesName === 'Valore medio') {
                      tooltipContent += `Valore medio: ${value.toLocaleString('it-IT', {maximumFractionDigits: 2}) + ' µg m^-3'}<br>`;
                    }
                  });

                  return tooltipContent;
                }
              },
              legend: {
                data: ['Valore minimo', 'Valore medio', 'Valore massimo']
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: this.atmosphereTimeList
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter:  function (params:any) {
                    return `${params.toLocaleString('it-IT', {maximumFractionDigits: 2})}` + ' µg m^-3';
                  }
                }
              },
              series: [
                {
                  name: 'Valore minimo',
                  type: 'line',
                  data: this.atmosphereMinValue
                },
                {
                  name: 'Valore medio',
                  type: 'line',

                  data: this.atmosphereAvgValue
                },
                {
                  name: 'Valore massimo',
                  type: 'line',

                  data: this.atmosphereMaxValue
                },

              ]
            };
            this.spinner.hide();

          }
        )
      } else {
        this.dataService.getDailyAtmosphereData(this.selectedMakerLatitude, this.selectedMarkerLongitude, this.selectedStartDate.value.format('YYYY-MM-DD'), this.selectedEndDate.value.format('YYYY-MM-DD'), this.selectedAtmosphereParameter.value).subscribe(
          (data) => {
            this.atmosphereTimeList = [];
            this.atmosphereMinValue = [];
            this.atmosphereMaxValue = [];
            this.atmosphereAvgValue = [];

            data.forEach((item: any) => {
              const dateObject = new Date(item.data);
              const day = dateObject.getDate();
              const month = dateObject.getMonth() + 1;
              const year = dateObject.getFullYear();
              const formattedDate = `${day}-${month}-${year}`;

              this.atmosphereTimeList.push(formattedDate);
              this.atmosphereMinValue.push(item.min);
              this.atmosphereMaxValue.push(item.max);
              this.atmosphereAvgValue.push(item.avg);
            });

          },
          () => {
            this.spinner.hide()
          },
          () => {
            this.atmosphereMergedOption = {
              tooltip: {
                trigger: 'axis',
                formatter: (params: any) => {
                  const dataIndex = params[0]?.dataIndex ?? 0;
                  const time = this.atmosphereTimeList[dataIndex];
                  let tooltipContent = `Data: ${time}<br>`;

                  params.forEach((param: any) => {
                    const seriesName = param.seriesName;
                    const value = param.value;

                    if (seriesName === 'Valore minimo') {
                      tooltipContent += `Valore minimo: ${value.toLocaleString('it-IT', {maximumFractionDigits: 2}) + ' µg m^-3'}<br>`;
                    } else if (seriesName === 'Valore massimo') {
                      tooltipContent += `Valore massimo: ${value.toLocaleString('it-IT', {maximumFractionDigits: 2}) + ' µg m^-3'}<br>`;
                    } else if (seriesName === 'Valore medio') {
                      tooltipContent += `Valore medio: ${value.toLocaleString('it-IT', {maximumFractionDigits: 2}) + ' µg m^-3'}<br>`;
                    }
                  });

                  return tooltipContent;
                }
              },
              legend: {
                data: ['Valore minimo', 'Valore medio', 'Valore massimo']
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: this.atmosphereTimeList
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter:  function (params:any) {
                    return `${params.toLocaleString('it-IT', {maximumFractionDigits: 2})}` + ' µg m^-3';
                  }
                }
              },
              series: [
                {
                  name: 'Valore minimo',
                  type: 'line',
                  data: this.atmosphereMinValue
                },
                {
                  name: 'Valore medio',
                  type: 'line',

                  data: this.atmosphereAvgValue
                },
                {
                  name: 'Valore massimo',
                  type: 'line',

                  data: this.atmosphereMaxValue
                },

              ]
            };
            this.spinner.hide();
          }
        )
      }
    }

  }

  public climateAxisLabelFormatter(value: any): string {
    // Utilizza la variabile selectedClimateParameter dichiarata in questa classe/componente
    let parameter = this.selectedClimateParameter.value;
    let unit = '';

    switch (parameter) {
      case 'Solar_Radiation':
        unit = ' J m-2 d-1';
        break;
      case 'Relative_Humidity':
        unit = '%';
        break;
      case 'Precipitation':
          unit = ' mm d-1';
        break;
      case 'Temperature_Air':
        unit = ' K';
        break;
      default:
        unit = ' m s-1';
    }

    return `${value.toLocaleString('it-IT', {maximumFractionDigits: 2})}${unit}`;
  }



  public formatTooltipContent(value: number): string {
    const parameter = this.selectedClimateParameter.value;
    let unit = '';

    switch (parameter) {
      case 'Solar_Radiation':
        unit = ' J m-2 d-1';
        break;
      case 'Relative_Humidity':
        unit = '%';
        break;
      case 'Precipitation':
          unit = ' mm d-1';
        break;
      case 'Temperature_Air':
        unit = ' K';
        break;
      default:
        unit = ' m s-1';
    }

    const formattedValue = `${value.toLocaleString('it-IT', { maximumFractionDigits: 2 })}${unit}`;
    return formattedValue;
  }


  public onMarkerClicked(event: any) {
    this.selectedMakerLatitude = event.lat;
    this.selectedMarkerLongitude = event.lng;
    this.getDataTable();
    this.getDataClimate();
  };


  public onChangingDepth(event: any): void {
    this.selectedDepth = event;
    this.getDataTable();
  };



  public _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.citiesList.filter(option => option.toLowerCase().includes(filterValue));
  };

  public getCitiesList(): void {
    this.dataService.getCities().subscribe(
      (data) => {
        this.citiesList = data.data.map((city: { COMUNE: any; }) => city.COMUNE);
      },
      () => {},
      () => {}
    );
  }



  public clearFilters() {
    this.selectedCity.setValue("");
    this.selectedStartDate.setValue(_moment(this.DEFAULT_START_DATE, "YYYY-MMD-DD"));
    this.selectedEndDate.setValue(_moment(this.DEFAULT_END_DATE, "YYYY-MMD-DD"));

  }



}
