import {Component, Input, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.css']
})
export class ChipsComponent {
  @Input() chips: string[] = [];

  public finalChips: string[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.cleanChipsList();
  }

  private cleanChipsList(): void {
    this.finalChips = [];
    for (let chip of this.chips) {
      if (Array.isArray(chip)) {
        for (let element of chip) {
          this.finalChips.push(element);
        }
      } else if (chip != null) {
        this.finalChips.push(chip);
      }
    }
  }

}
