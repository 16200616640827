import {HttpClient} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  private jsons: string = "../assets/json/";
  private API_URL: string = environment.baseUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  public getSoilgridsSinglePointsData(municipality: any): Observable<any> {
    const url = `${this.API_URL}/soilgrids/pointOfInterest?comune=${municipality}`
    return this.http.get(url);
  };

  public getMonthlyClimateData(lat:any, long:any, startDate:any, endDate:any, parameter: any): Observable<any> {
    const url = `${this.API_URL}/climate_data/mensili?lat=${lat}&long=${long}&startDate=${startDate}&endDate=${endDate}&nomeParametro=${parameter}`
    return this.http.get(url)
  };

  public getDailyAtmosphereData(lat:any, long:any, startDate:any, endDate:any, parameter: any): Observable<any> {
    const url = `${this.API_URL}/atmosphere/giornaliere?lat=${lat}&long=${long}&startDate=${startDate}&endDate=${endDate}&nomeParametro=${parameter}`
    return this.http.get(url)
      };

  public getMonthlyAtmosphereData(lat:any, long:any, startDate:any, endDate:any, parameter: any): Observable<any> {
    const url = `${this.API_URL}/atmosphere/mensili?nomeParametro=${parameter}&lat=${lat}&long=${long}&startDate=${startDate}&endDate=${endDate}`
    return this.http.get(url)
  };

  public getDailyClimateData(lat:any, long:any, startDate:any, endDate:any, parameter: any): Observable<any> {
    const url = `${this.API_URL}/climate_data/giornaliere?nomeParametro=${parameter}&lat=${lat}&long=${long}&startDate=${startDate}&endDate=${endDate}`
    return this.http.get(url)
  };



  public getCities(): Observable<any> {
    return this.http.get(this.jsons + 'comuni_puglia.json');
  };

  public openSnackBar(text: string, confirm: string, duration: number): void {
    this.snackBar.open(text, confirm, {
      duration: duration,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ['blue-snackbar']
    });
  }






}

