<mat-card>
  <div class="container">
      <div class="float-end">
        <mat-slide-toggle  [checked]="false" [(ngModel)]="isSatelliteView" (change)="changeView()" [color]="'primary'">Vista satellitare</mat-slide-toggle>
      </div>
  </div>
  <div class="container">
    <div class="row mx-auto justify-content-center">
      <div id="map"></div>
    </div>
    <div class="row mx-auto">
      <p>{{source}}</p>
    </div>
  </div>
</mat-card>



